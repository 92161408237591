import { fsPost } from './service.js'

export const apis = {
    getToken: function (data) {
        const vData = {
            method: 'get_token',
            data: data
        }
        return fsPost({
            url: '/get_token.do',
            data: vData
        })
    },
    jsonapi: function (method, data) {
        const vData = {
            method: method,
            data: data
        }
        return fsPost({
            url: '/json.do',
            data: vData
        })
    },
    login: (data) => { },
    upFile: (data) => { },
    downFile: (data) => {
        const vData = {
            method: 'down_file',
            data: data
        }
        return fsPost({
            url: '/dfile.do',
            data: vData
        })
    },
    lFistFile: (data) => { }
}
