<template>
    <div class="header_bar">
        <img src="../assets/m_fs-logo-z.jpg" class="header_img">
        <div class="header_title">文享阁</div>
        <div class="header_title_2"> ---- 文件分享于天地，流转于人间，存留于此阁，谓之文享阁</div>
    </div>
</template>

<script>
export default {
    name: 'HeaderCom'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header_bar{
    width: 100%;
    height: 100%;
    display: flex;
    background-color:royalblue
}
.header_img{
    margin-left: 20px;
    padding: 5px 3px;
    width: 70px;
    height: 70px;
    border-radius: 45%;
}
.header_title{
    margin-left: 24px;
    font-weight: bold;
    padding-top: 25px;
    font-size:36px;
    color:white
}
.header_title_2{
    margin-left: 48px;
    font-style:italic;
    padding-top: 48px;
    font-size:13px;
    color:white
}
</style>
