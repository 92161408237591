import { createStore } from 'vuex'

export default createStore({
  state: {
    jwt_token: '',
    user_info: {
      v_days: 1,
      v_limit: 5242880, // 单位字节
      v_space: 1, // 单位G
      v_type: 0,
      v_used: 0 // 单位字节
    },
    send_list: [
      // { filecode: 's65281', filename: 'logo.png', validate: '2024-04-04 12:00:00' },
      // { filecode: 's87372', filename: 'xiogndilian.doc', validate: '2024-04-04 12:00:00' }
    ]
  },
  getters: {
    //
  },
  mutations: {
    // 同步调用
    set_jwt(state, token) {
      state.jwt_token = token
    },
    set_info(state, info) {
      state.user_info = info
    },
    add_records(state, record) {
      // 数据插入头部
      state.send_list.unshift(record)
    },
    set_records(state, records) {
      // 数据插入头部
      state.send_list = records
    },
    add_used(state, filesize) {
      state.user_info.v_used = state.user_info.v_used + filesize
    }
  },
  actions: {
    // 异步调用
  },
  modules: {
    //
  }
})
