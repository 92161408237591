<template>
    <div class="file-list">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="filecode" label="取件码" width="180px" />
            <el-table-column prop="filename" label="文件名" />
            <el-table-column prop="validate" label="有效期" width="180px" />
            <el-table-column label="操作" width="180px">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click.prevent="fnDownFile(scope.$index)">
                        下载
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <el-button class="mt-4" style="width: 100%" @click="onAddItem">Add Item</el-button> -->
    </div>
</template>

<script>
import { onMounted, onBeforeUnmount, computed } from 'vue'
import { useStore } from 'vuex'
import emiter from '../bus'
import { apis } from '@/request'
import store from '@/store'
export default {
    setup() {
        const stroe = useStore()
        // const tableData = ref(stroe.state.send_list)
        const tableData = computed(() => {
            return store.state.send_list
        })
        console.log('tableData=', tableData)
        const onAddItem = () => {
            const record = { filecode: 'ssss', filename: 'xiogndilian.doc', validate: '2024-04-04 12:00:00' }
            stroe.commit('add_records', record)
        }
        const fnDownFile = (index) => {
            const obj = tableData.value[index]
            console.log('obj=', obj)
            console.log('obj filecode=', obj.filecode)
            const a = document.createElement('a')
            a.download = ''
            a.target = '_blank'
            a.href = 'dfile.do?code=' + obj.filecode + '&t=' + String(new Date().getTime())
            a.click()
        }
        const refreshTableData = (msg) => {
            console.log('refreshTableData = ', msg)
            apis.jsonapi('refresh_table_data', {}).then(res => {
                console.log('refreshTableData=', res)
                stroe.commit('set_records', res.data)
            })
        }
        onMounted(() => {
            emiter.on('refreshTableData', refreshTableData)
        })
        onBeforeUnmount(() => {
            emiter.off('sayhi')
        })
        return { tableData, onAddItem, fnDownFile }
    }
}
</script>

<style scoped>
.file-list {
    background-color: white;
}
</style>
