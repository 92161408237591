import {
  ElButton, ElContainer, ElHeader, ElAside, ElMain, ElForm,
  ElFormItem, ElInput, ElTable, ElTableColumn, ElUpload, ElMessageBox,
  ElDialog
} from 'element-plus'

export default (app) => {
  app.use(ElButton)
  app.use(ElContainer)
  app.use(ElHeader)
  app.use(ElAside)
  app.use(ElMain)
  app.use(ElForm)
  app.use(ElFormItem)
  app.use(ElInput)
  app.use(ElTable)
  app.use(ElTableColumn)
  app.use(ElUpload)
  app.use(ElMessageBox)
  app.use(ElDialog)
}
