<template>
    <div class="content_area">
        <DownFileCom></DownFileCom>
        <UpFileCom></UpFileCom>
    </div>
</template>

<script>
import UpFileCom from './UpFileComp.vue'
import DownFileCom from './DownFileComp.vue'
// import EventBus from '../bus'
// import { onBeforeUnmount } from 'vue'

export default {
    name: 'ContentCom',
    components: {
        UpFileCom, DownFileCom
    },
    setup() {
        // 定义一个打招呼的方法
        // const sayHi = (msg) => {
        //     console.log('EventBus=', msg)
        // }

        // // 启用监听
        // EventBus.on('sayHi', sayHi)

        // // 在组件卸载之前移除监听
        // onBeforeUnmount(() => {
        //     EventBus.off('sayHi', sayHi)
        // })
    }
}
</script>

<!-- Add "scoped" .attribute to limit CSS to this component only -->
<style scoped>
.content_area {
    background-color: white;
    min-height: 700px;
}
</style>
