<template>
    <div class="file-control">
        <div class="up-file">
            <el-upload ref="uploadRef" action='ufile.do' :limit="1" :on-exceed="handleExceed" :on-success="onSuccess"
                :on-error="onError" :data="ruleForm" :headers="jwtToken" :on-response="handleResponse"
                :before-upload="beforeUpload" :auto-upload="false">
                <template #trigger>
                    <el-button type="primary">选择文件</el-button>
                </template>
                <el-button class="ml-3" type="success" @click="submitUpload">
                    上传文件
                </el-button>
                <template #tip>
                    <div class="el-upload__tip text-red">
                        只能上传单个文件,大小不超过 {{ fileSize }}
                    </div>
                </template>
            </el-upload>
        </div>
        <div class="down-file">
            <el-form :inline="true" class="form-inline">
                <el-form-item label="取件码：">
                    <el-input placeholder="取件码" clearable v-model="downcode" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="downFile">取件</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, ref, computed } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useStore } from 'vuex'

export default {
    setup() {
        const uploadRef = ref(null)
        const store = useStore()
        console.log('setup=', store.state)

        const data = reactive({
            fileList: [],
            filename: '',
            // fileSize: '50m',
            ruleForm: {
                name: 'fs-share',
                sec: 'seckey'
            },
            jwtToken: {
                // authorization: localStorage.getItem('jwt_token')
                authorization: store.state.jwt_token
            },
            downcode: ''
        })

        const fileSize = computed(() => {
            const info = store.state.user_info
            if (info.v_limit) {
                if (info.v_limit < 1024 * 1024 * 1024) {
                    return Math.round(info.v_limit / 1024 / 1024) + 'M'
                } else {
                    return Math.round(info.v_limit / 1024 / 1024 / 1024) + 'G'
                }
            } else {
                return '2M'
            }
        })

        const onSuccess = (res, uploadFiles) => {
            uploadRef.value.clearFiles()
            if (res.code === '2000') {
                // 清除文件
                console.log('res=', res)
                const info = res.data.info
                // 添加记录
                const record = { filecode: info.filecode, filename: info.filename, validate: info.validate }
                store.commit('add_records', record)
                // 修改已用空间
                store.commit('add_used', info.filesize)
            } else {
                if (res.code === '4001') {
                    ElMessageBox.alert('会话认证失败，刷新页面重新登陆', '提醒', {
                        confirmButtonText: 'OK',
                        callback: (action) => {
                            location.reload()
                        }
                    })
                } else {
                    ElMessage({
                        type: 'error',
                        message: '上传文件失败!',
                        duration: 2000
                    })
                }
            }
            console.log('onSuccess=', res, uploadFiles)
        }

        const onError = (files, uploadFiles) => {
            console.log('onError=', files, uploadFiles)
            ElMessageBox.alert('服务器异常!请重新登陆', '提醒', {
                // if you want to disable its autofocus
                // autofocus: false,
                confirmButtonText: 'OK',
                callback: (action) => {
                    location.reload()
                }
            })
        }
        const handleResponse = (response, uploadFile) => {
            console.log('Headers:', response.headers)
        }
        const handleExceed = (files, uploadFiles) => {
            console.log('handleExceed=', files, uploadFiles)
            uploadRef.value.clearFiles()
            const file = files[0]
            uploadRef.value.handleStart(file)
        }

        const beforeUpload = (file) => {
            // 支持jwt
            const userInfo = store.state.user_info
            data.jwtToken.authorization = store.state.jwt_token
            // 在这里可以添加你的验证逻辑
            console.log('文件大小', file.size, userInfo.v_limit)
            const isLt2M = file.size < userInfo.v_limit
            console.log('烦烦烦 =', isLt2M)
            if (!isLt2M) {
                ElMessageBox.alert('上传的文件大小超过限制，请联系客服提升会员等级!')
                return false
            }
            const isGTStore = (file.size + userInfo.v_used) < userInfo.v_space * 1024 * 1024 * 1024
            if (!isGTStore) {
                ElMessageBox.alert('存储空间超过限制，请联系客服提升会员等级!')
                return false
            }
            return true
        }

        const submitUpload = (event) => {
            data.jwtToken.authorization = store.state.jwt_token
            uploadRef.value.submit()
        }

        const downFile = () => {
            console.log('downcode=', data.downcode)
            if (data.downcode === '') {
                ElMessageBox.alert('请输入取件码')
            } else {
                const a = document.createElement('a')
                a.download = ''
                a.target = '_blank'
                a.href = 'dfile.do?code=' + data.downcode + '&t=' + String(new Date().getTime())
                a.click()
                // console.log('down file === ')
                // apis.downFile({ code: data.downcode })
            }
        }

        return { ...toRefs(data), uploadRef, handleExceed, submitUpload, beforeUpload, onSuccess, onError, handleResponse, downFile, fileSize }
    }
}
</script>

<style scoped>
.ml-3 {
    margin-left: 10px;
}

.file-control {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    height: 110px;
}

.form-inline {
    display: flex;
    align-content: flex-start;
}

.up-file {
    padding-left: 10px;
}

.down-file {
    padding-right: 10px;
}
</style>
