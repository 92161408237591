import axios from 'axios'
import router from './router'
import store from './store'

const Service = axios.create({
    timeout: 8000,
    // 定义前缀请求
    baseURL: '',
    headers: {
        'Content-type': 'application/json;charset=utf-8'
    }
})

// 如果是会员状态就会启动锁屏定时器，并弹窗，引导用户重新登陆使用，或者切换匿名使用，但是当前内容全部清除
// const timer

// 请求拦截,请求前的处理
Service.interceptors.request.use(param => {
    console.log('inter request ')
    // 增加jwt的支持,方便后台及进行验证只用
    // const vToken = localStorage.getItem('jwt_token')
    // const vLoginFlag = localStorage.getItem('is_login')
    const vToken = store.state.jwt_token
    if (vToken) {
        param.headers.authorization = vToken
    }
    return param
}, error => {
    console.log('req error=', error)
    // return Promise.reject(error)
    router.push({ name: 'about' })
})

// 响应拦截，相应后的处理
Service.interceptors.response.use(config => {
    console.log('inter response ')
    // 增加jwt的支持，方便后台验证只用
    const vToken = config.headers.authorization
    if (vToken) {
        // localStorage.setItem('jwt_token', vToken)
        store.commit('set_jwt', vToken)
    }
    return config.data
}, error => {
    console.log('rep error=', error)
    // return Promise.reject(error)
    router.push({ name: 'about' })
})

// post 封装
export const fsPost = config => {
    return Service({
        ...config,
        method: 'post',
        data: config.data
    })
}

// get 封装
export const fsGet = config => {
    return Service({
        ...config,
        method: 'get',
        data: config.data
    })
}
