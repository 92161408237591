<template>
  <div class="common-layout">
    <el-container>
      <el-header height="80px">
        <HeaderCom></HeaderCom>
      </el-header>
      <el-container class="main_content">
        <el-aside width="248px">
          <LoginCom></LoginCom>
        </el-aside>
        <el-main>
          <ContentCom></ContentCom>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderCom from '../components/HeaderComp.vue'
import LoginCom from '../components/LoginComp.vue'
import ContentCom from '../components/ContentComp.vue'
import { apis } from '../request.js'
import router from '../router'
import { useStore } from 'vuex'

export default {
  name: 'App',
  components: {
    HeaderCom, LoginCom, ContentCom
  },
  setup(props, context) {
    console.log('1111')
    const store = useStore()
    apis.getToken({
    }).then(res => {
      console.log('res=', res)
      if (res.code === '2000') {
        // 保存 jwt token
        store.commit('set_jwt', res.data.jwt_token)
        store.commit('set_info', res.data.info)
        console.log('app=', res.data.info)
        console.log('store=', store.state)
      } else {
        console.log('22222')
        router.push({ name: 'about' })
      }
    })
    return {}
  }
}
</script>

<style>
.main_content {
  padding: 0 20px;
}
</style>
