<template>
    <div class="login_col">
        <div class="login_row">
            <div class="login_lable">身份：</div>
            <div class="login_text">{{ cRole }}</div>
        </div>
        <div class="login_row">
            <div class="login_lable">大小：</div>
            <div class="login_text">{{ cSzie }}(单文件)</div>
        </div>
        <div class="login_row">
            <div class="login_lable">空间：</div>
            <div class="login_text">{{ cUsed }} / {{ cSpace }}</div>
        </div>
        <div class="login_row">
            <div class="login_lable">有效：</div>
            <div class="login_text">{{ cDays }} 天</div>
        </div>
        <div class="login_row" v-show="isVip">
            <div class="login_lable">编号：</div>
            <div class="login_text">{{ cUid }} </div>
        </div>
        <div class="login_row" v-show="isVip">
            <div class="login_lable">截止：</div>
            <div class="login_text">{{ cEndTine }} </div>
        </div>
        <div class="login_row" v-show="false">
            <div class="login_lable">信用：</div>
            <div class="login_text">{{ cCredit }} 点</div>
        </div>
        <div class="login_row" v-show="false">
            <div class="login_btn">
                <el-button style='width:120px;height: 18px;' type="success" @click="btnUp">续费</el-button>
            </div>
        </div>
        <div class="login_row" v-show="!isVip">
            <div class="login_btn">
                <el-button style='width:120px;height: 18px;' type="success" @click="btnUp">登陆</el-button>
            </div>
        </div>
        <div class="login_row" v-show="false">
            <div class="login_btn">
                <el-button style='width:120px;height: 18px;' type="success" @click="btnUp">升级</el-button>
            </div>
        </div>
        <div class="login_gap">
        </div>
        <div class="login_row" v-show="isVip">
            <div class="login_txt">
                服务到期<br>
                自动变为普通会员<br>
                等级：限制：空间：时效<br>
                游客：5M：1G：1天<br>
                普通：10M：10G：2天<br>
                铜牌：100M：20G：3天<br>
                银牌：300M：40G：4天<br>
                金牌：400M：80G：5天<br>
                钻石：1G：160G：7天<br>
            </div>
        </div>
        <div class="login_gap">
        </div>
        <div class="login_row">
            <div class="login_lable">微信客服：</div>
            <div class="login_text">mx20222052</div>
        </div>
        <div class="login_row">
            <div class="login_btn">
                <img width="180px" height="245px" src="../assets/kefu.jpg" />
            </div>
        </div>
    </div>
    <el-dialog v-model="centerDialogVisible" title="微信扫码登陆" width="280px" center :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false">
        <div class="mp_col">
            <div class="mp_row">
                <img width="220px" height="220px" src="../assets/file-share-logo.jpg" />
            </div>
            <div class="mp_row">
                <div class="mp_btn">
                    登陆码： {{ loginCode }} 失效自动更新
                </div>
            </div>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <!-- <el-button @click="centerDialogVisible = false">取消</el-button> -->
                <el-button type="primary" @click="closeDialog">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { reactive, toRefs, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { apis } from '../request.js'
import emiter from '../bus'
import { ElMessageBox } from 'element-plus'

export default {
    name: 'LoginCom',
    setup() {
        const centerDialogVisible = ref(false)
        const store = useStore()
        const data = reactive({
            isVip: false,
            credit: 0,
            loginCode: '7298374',
            endDate: ''
        })
        // 类型
        const cRole = computed(() => {
            const info = store.state.user_info
            let ret = '普通游客'
            if (info.v_type) {
                switch (info.v_type) {
                    case 0: ret = '普通游客'; break
                    case 1: ret = '普通会员'; break
                    case 2: ret = '铜牌会员'; break
                    case 3: ret = '银牌会员'; break
                    case 4: ret = '金牌会员'; break
                    case 5: ret = '钻石会员'; break
                }
            }

            return ret
        })
        // 限制
        const cSzie = computed(() => {
            const info = store.state.user_info
            if (info.v_limit < 1024 * 1024 * 1024) {
                return Math.round(info.v_limit / 1024 / 1024) + 'M'
            } else {
                return Math.round(info.v_limit / 1024 / 1024 / 1024) + 'G'
            }
        })
        // 已用空间
        const cUsed = computed(() => {
            const info = store.state.user_info
            if (info.v_used < 1024) {
                return info.v_used + 'b'
            } else if (info.v_used < 1024 * 1024) {
                return Math.round(info.v_used / 1024) + 'k'
            } else if (info.v_used < 1024 * 1024 * 1024) {
                return Math.round(info.v_used / 1024 / 1024) + 'm'
            } else {
                return Math.round(info.v_used / 1024 / 1024 / 1024) + 'G'
            }
        })
        // 可用空间
        const cSpace = computed(() => {
            const info = store.state.user_info
            return info.v_space + 'G'
        })
        // 有效时间
        const cDays = computed(() => {
            const info = store.state.user_info
            return info.v_days
        })
        const cUid = computed(() => {
            const info = store.state.user_info
            return info.v_vxid
        })
        const cCredit = computed(() => {
            const info = store.state.user_info
            return info.v_credit
        })
        // v_endtime
        const cEndTine = computed(() => {
            const info = store.state.user_info
            return info.v_endtime
        })
        const closeDialog = () => {
            // 关闭定时器
            console.log('closeDialog=')
            if (loginTimer) {
                clearInterval(loginTimer)
            }
            centerDialogVisible.value = false
        }
        let loginTimer
        const btnUp = (event) => {
            console.log('isVip=', data.isVip)
            // data.isVip = !data.isVip
            centerDialogVisible.value = true
            // 获取新login_code
            apis.jsonapi('get_login_code', {}).then((res) => {
                console.log('get_login_code res=', res)
                if (res.code >= '4000') {
                    ElMessageBox.alert('浏览器会话失效，自动刷新', '提醒', {
                        // if you want to disable its autofocus
                        // autofocus: false,
                        confirmButtonText: 'OK',
                        callback: (action) => {
                            location.reload()
                        }
                    })
                    return
                }
                // 启动定时器3秒一次的定时器
                data.loginCode = res.data.login_code
                if (loginTimer) {
                    clearInterval(loginTimer)
                }
                loginTimer = setInterval(() => {
                    console.log('timer 3 sec =')
                    // 请求检查logincode
                    apis.jsonapi('check_login_code', { login_code: data.loginCode }).then(res => {
                        if (res) {
                            console.log('check_login_code =', res)
                            if (res.code === '2000') {
                                //  suc
                                data.isVip = true
                                store.commit('set_info', res.data.info)
                                // 调用打招呼事件，传入消息内容
                                emiter.emit('refreshTableData', '登陆成功，请更新表格')
                                closeDialog()
                            }
                            if (res.code === '2001') {
                                // wait
                            }
                            if (res.code === '2002') {
                                // refresh
                            }
                            if (res.code === '2003') {
                                // fail
                                closeDialog()
                            }
                        }
                    })
                }, 3 * 1000)
            })
        }
        return { ...toRefs(data), btnUp, cRole, cSzie, cUsed, cSpace, cDays, cUid, cCredit, cEndTine, centerDialogVisible, closeDialog }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login_col {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    border-top: 1px solid rgb(131, 12, 243);
    background-color: powderblue;

    .login_row {
        padding-top: 10px;
        display: flex;

        .login_lable {
            widows: 80px;
        }

        .login_btn {
            width: 180px;
            padding-top: 10px;
            display: flex;
            justify-content: center;
        }

        .login_txt {
            width: 180px;
            padding-top: 10px;
            display: flex;
            justify-content: left;
            text-align: left
        }
    }

    .login_gap {
        height: 20px;
    }
}

.mp_col {
    width: 100%;
    height: 100%;

    .mp_row {
        padding-top: 10px;
        display: flex;

        .mp_btn {
            width: 180px;
            padding-top: 20px;
            font-size: 20px;
            display: flex;
            justify-content: center;
        }
    }

    .mp_gap {
        height: 20px;
    }
}
</style>
